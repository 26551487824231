.sidebar {
  .ps-sidebar-backdrop {
    background-color: rgba($black, 0.5);
  }
  .sidebar-bottom {
    ul {
      width: 100%;
    }
  }
  .ps-sidebar-container {
    display: flex;
    flex-direction: column;
  }
  * {
    color: $white;
  }

  .active {
    border-radius: 0.375rem;
    background-color: $indigo-700 !important;
    color: $white !important;
  }

  > div {
    background-color: $primary;
  }
  .ps-menu-button:hover {
    border-radius: 0.375rem;
    background-color: $indigo-700 !important;
  }
  &.ps-collapsed {
    .sidebar-logo {
      // padding-left: 0px !important;
    }
  }
  .ps-submenu-content {
    padding-left: 24px;
    background-color: $primary;
  }
  
  // New styles for more compact sidebar
  .ps-menu-button {
    padding: 8px 12px !important;
    min-height: auto !important;
  }
  
  .ps-menu-label {
    font-size: 0.9rem;
  }
}

[dir="rtl"] {
  .ps-submenu-content {
    padding-right: 24px;
    background-color: $primary;
  }
  .sidebar.ps-collapsed .sidebar-logo {
    padding-left: 3rem;
    padding-right: 0px !important;
  }
}
