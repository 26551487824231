
.nav-tabs{
    border-top: 1px solid $graycool-200;
    &.none-top-border{
        border-top:none;
        border-bottom:none;
        .nav-link{
            padding: 1rem 0;
        }
    }
    .nav-link{
        border:none;
        color:$graycool-500;
        font-weight: $font-weight-semibold;
        padding: 1rem 1.5rem;

        &.active{
            color:$primary;
            font-weight: $font-weight-bolder;
            border:none;
            border-bottom: 2px solid $primary;
        }
    }
}