.accordion {
  .accordion-header {
  }
  .accordion-item {
    padding: 2rem 2rem;
  }
  .accordion-button {
    padding: 0;
    &:first-of-type {
      padding: 0 0 1.5rem 0;
    }
    font-size: $h6-font-size;
    font-weight: $font-weight-bold;
    color: $graycool-900;
  }
  .accordion-body{
    padding:2rem 0 0 0;
    color:$graycool-400;
    font-size: $h7-font-size;
    font-weight: $font-weight-semibold;
  }
  &.compact{
    .accordion-item{
      padding: 1rem 2rem;
    }
    .accordion-button{
      padding: 0;
    }
  }
}

[dir="rtl"]{
  .accordion-button::after{
    margin-right:auto;
    margin-left:inherit;
  }
}
