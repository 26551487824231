.modal-header {
  font-size: $h6-font-size;
  color: $graycool-900;
  font-weight: $font-weight-bold;
}

[dir="rtl"] .modal-header .btn-close {
  margin-left: inherit;
  margin-right: auto;
}
