.alert{
    font-size:$h7-font-size;
    font-weight:$font-weight-semibold;
}

.alert-primary{
    border: 1px solid $primary;
    // color: $gray-600;
}

.alert-white{
    border: 1px solid $graycool-200;
    color: $gray-600;
}

.alert-info{
    border:1px solid $info;
    background-color: $blue-50 ;
    color:$info;
}

.alert-warning{
    background-color: $warning-50;
    color: $warning-700;
    border-color: $warning-500;
    border-width: 1px;
}

.alert-danger{
    background-color: $danger-50;
    border-color: $danger-600;
    border-width: 1px;
    color:$danger-600;
}