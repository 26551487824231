.btn-secondary{
  color:white;
}

.btn-danger {
  border: none;
  background-color: $red-100;
  color: $danger-600;
}

.btn-info {
  border: none;
  background-color: $blue-100;
  color: $blue-700;
}

.btn-light {
  color: $graycool-700;
}

.btn-group {
  .btn-outline-light {
    color: $graycool-300;
    background-color: $gray-100;
    &.active {
      color: $gray-900;
      background-color: $white;
    }
  }
}
