//layout
$layout-spacer-lg: 3rem;
$layout-spacer-x: 1.5rem;
//page
$max-width: 1024px;
$enable-negative-margins: true;

$blue: #175cd3;
$blue-50: #eff8ff;
$blue-100: #d1e9ff;
$blue-200: #b3ddff;
$blue-400: #2bafcd;
$blue-600: #2f85c8;
$blue-700: #175cd3;

$primary-50: #3c4da428;
$indigo-400: #3c4da4;
$indigo-500: #314195;
$indigo-600: #314195;
$indigo-700: #2b3b88;

$purple: #6f42c1;
$pink: #d63384;
$red: #d92d20;
$danger-50: #fef3f2;
$red-100: #fee4e2;
$red-200: #fecdca;
$danger-600: #d92d20;

$orange: #fd7e14;

$yellow: #dc6803;
$yellow-100: #fffaeb;

$warning-50: #fffaeb;
$warning-500: #f79009;
$warning-700: #b54708;

$green: #039855;
$green-100: #d1fadf;
$teal: #20c997;
$cyan: #0dcaf0;

$graycool-50: #f9f9fb;
$graycool-200: #dcdfea;
$graycool-300: #b9c0d4;
$graycool-400: #7d89b0;
$graycool-500: #5d6b98;
$graycool-600: #4a5578;
$graycool-700: #404968;
$graycool-900: #111322;

//colors

$white: #fff;
$gray-100: #eff1f5;
$gray-200: #dcdfea;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6b6b6b;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$primary: $indigo-600;
$secondary: $blue-600;
$success: $green;
$info: $blue-700;
$warning: $yellow;
$danger: $red;
$gray-blue: $graycool-500;
$light: $gray-200;
$dark: $gray-900;
$body-color: $graycool-700 !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "gray": $gray-600,
  "light": $light,
  "dark": $dark,
  "graycool": $graycool-600,
  "dark-graycool": $graycool-900,
  "light-graycool": $graycool-50,
  "light-blue": $blue-50,
);

//weights
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;

//spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 5,
);

//line height
$line-height-base: 1.25 !default;
$line-height-sm: 1 !default;
$line-height-lg: 2 !default;

//Navbar
$nav-link-color: $white;

//text
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$paragraph-margin-bottom: 0;

$h1-font-size: $font-size-base * 3 !default; //XXL
$h2-font-size: $font-size-base * 2.5 !default; //XL
$h3-font-size: $font-size-base * 2 !default; //LG
$h4-font-size: $font-size-base * 1.5 !default; //MD
$h5-font-size: $font-size-base * 1.25 !default; //SM
$h6-font-size: $font-size-base * 1 !default; //XS
$h7-font-size: $font-size-base * 0.875 !default; //XXS
$headings-line-height: 1.3;

$body1-font-size: $font-size-base * 1.125 !default;
$body2-font-size: $font-size-base * 1 !default;
$body3-font-size: $font-size-base * 0.875 !default;
$body4-font-size: $font-size-base * 0.75 !default;
$body5-font-size: $font-size-base * 0.625 !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size,
) !default;

$body-font-sizes: (
  1: $body1-font-size,
  2: $body2-font-size,
  3: $body3-font-size,
  4: $body4-font-size,
  5: $body5-font-size,
) !default;

$hr-opacity: 1 !default;
$hr-border-width: 0 !default;

// scss-docs-start border-radius-variables
$border-radius: 0.75rem;
$border-radius-sm: 0.5rem;
$border-radius-lg: 1rem;
$border-radius-xl: 1rem;
$border-radius-2xl: 2rem;
$border-radius-pill: 50rem;
// scss-docs-end border-radius-variables

//Forms
$input-color: $graycool-500;
$input-padding-y: 0.875rem;
$input-padding-x: 0.875rem;
$input-btn-line-height: $line-height-base !default;

// $input-height:
// $input-height-sm:
$input-height-lg: 3.25rem;

$input-padding-y-lg: 0.875rem;
$input-padding-x-lg: 0.875rem;
$input-font-size-lg: $body3-font-size;

$input-border-radius: $border-radius;
$input-border-radius-sm: $border-radius-sm;
$input-border-radius-lg: $border-radius-sm;
$input-line-height: 1.625;

// $form-check-padding-start: 0;
$form-check-input-border-radius: $border-radius-sm;
$form-check-input-width: 1.2em;

$form-select-indicator-color: $graycool-300 !default;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'><path  stroke='#{$form-select-indicator-color}' fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

$form-switch-width: 5.75em;
$form-switch-border-radius: $border-radius-sm;

////Labels
$form-label-font-size: map-get($font-sizes, 7);
$form-label-font-weight: $font-weight-semibold;
$form-label-color: $graycool-700;

//button
$btn-font-weight: $font-weight-semibold;
$btn-padding-y: 0.75rem;
$btn-padding-x: 1.5rem;
$btn-font-size: $body2-font-size;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-lg: 1rem;
$btn-padding-x-lg: 1.5rem;
$btn-font-size-lg: $h6-font-size;

$btn-border-radius: 0.75rem;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius-lg;

//Cards
$card-spacer-y: $spacer * 1.5;
$card-spacer-x: $spacer * 0;
$card-title-spacer-y: $spacer * 0;
$card-cap-color: $graycool-900 !default;
$card-border-color: $graycool-200;
$card-cap-padding-y: $card-spacer-y;
$card-cap-padding-x: 0;
$card-cap-bg: none;

//Badges
$badge-font-size: 0.75rem;
$badge-font-weight: $font-weight-semibold;
$badge-padding-y: 0.25rem;
$badge-padding-x: 0.5rem;
$badge-border-radius: 1rem;

//Tables
$table-cell-padding-y: 1rem 1rem;
$table-cell-padding-x: 1rem 1.5rem;
$table-th-font-weight: $font-weight-semibold;

//Alerts
$alert-border-width: 0;

//Accordion
$component-active-bg: $primary !default;
$accordion-button-active-bg: $white;

// Breadcrums
// $breadcrumb-font-size:              null;
// $breadcrumb-padding-y:              0;
// $breadcrumb-padding-x:              0;
$breadcrumb-item-padding-x:         0.75rem;
// $breadcrumb-margin-bottom:          1rem;
// $breadcrumb-bg:                     null;
$breadcrumb-divider-color: $gray-200;
$breadcrumb-active-color: #1570ef;
$breadcrumb-divider: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.940002 11.28L5.28667 6.93333C5.8 6.42 5.8 5.58 5.28667 5.06667L0.940002 0.720001' stroke='%23DCDFEA' stroke-width='1.25' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !default;
// $breadcrumb-divider-flipped:        $breadcrumb-divider;
// $breadcrumb-border-radius:          null;
