.document-tabs {
  background-color: $graycool-50;
  border: 1px $gray-100 solid;
  border-radius: 8px;
  padding: 4px;
  .nav-link {
    font-weight: $font-weight-semibold;
    color: $graycool-400;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    border-radius: 6px;
    background-color: $white;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
      0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    color: $gray-900;
  }
}
.page-item {
  margin: 2px;
  border-radius: 4px;
  overflow: hidden;
  color: $graycool-600;
  font-weight: $font-weight-semibold;
  border: none;
  * {
    border: none !important;
  }
  &.active {
    .page-link {
      background-color: $light !important;
      color: $graycool-600;
    }
  }
}
.icon {
  &.dark {
    * {
      stroke: $graycool-700;
    }
  }
}

.apply {
  @media (max-width: 576px) {
    .modal-body {
      padding: 8px !important;
    }
    .nav-link {
      font-weight: $font-weight-semibold;
      color: $graycool-400;
      &.active {
        color: $primary;
      }
    }
  }
  .category {
    cursor: pointer;
    &:hover {
      background-color: $gray-100;
    }
    &.active {
      background-color: $gray-200;
    }
  }
  .school {
    cursor: pointer;
    &:hover {
      background-color: $gray-100;
    }
  }
  .list {
    max-height: 400px;
  }
  .nav-underline .nav-link {
    border-color: $gray-500;
    &.active {
      border-color: $primary;
    }
  }
}

@for $i from 1 through 6 {
  $zIndexBackdrop: #{1000 + (5 * $i)};
  $zIndexContent: #{1000 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

.content-container {
  padding-bottom: 4rem !important;
  @include media-breakpoint-up(sm) {
    padding-bottom: 1.5rem !important;
  }
}
.amount-input {
  .minus {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right-width: 0 !important;
    border-left-width: 2px !important;
  }
  .plus {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left-width: 0 !important;
    border-right-width: 1px !important;
  }
  .input-group-text {
    border-top-right-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-width: 2px !important;
  }
}

.document-generator {
  background-color: $blue-100;
  color: $blue-700;
  font-size: $body4-font-size;
  font-weight: $font-weight-bold;
  border: 0;
  padding: 8px 42px;
  border-radius: $border-radius-sm;
}
.student-selector {
  .form-check-input[type="checkbox"] {
    border-radius: 0.2rem;
  }
}

.swal2-container {
  .swal2-styled.swal2-confirm {
    background-color: $primary !important;
  }
  .swal2-styled.swal2-deny {
    background-color: $danger !important;
  }
  .swal2-styled.swal2-cancel {
  }
}

.vh-100 {
  height: 100vh;
}

.header {
  .title {
    font-size: 4rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.dropdown-toggle {
  border: 1px solid $gray-200 !important;
}

.form-select {
  background-size: 18px 18px !important;
}

.form-switch-container {
  .form-check-input {
    height: 2rem;
  }
}

.form-check {
  .form-check-input {
    position: absolute;
    top: 16px;
    left: 42px;
  }
  .form-check-container {
    .form-check-label {
      margin: 0 0.75rem;
      color: $graycool-400 !important;
    }
    &.active {
      .form-check-label {
        color: $graycool-900 !important;
      }
    }
  }
}

.btn,
.form-control,
.dropdown-toggle,
.border-bottom {
  border-bottom-width: 2px !important;
}
.btn-outline-light {
  --bs-btn-color: $graycool-700 !important;
}

//PDF
.react-pdf__Page,
.react-pdf__Page__canvas {
  width: 100% !important;
}

.password-revealer {
  position: absolute;
  padding: $input-padding-y $input-padding-x;
  right: 0px;
  bottom: 0px;
  display: flex;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
}

.is-invalid ~ .password-revealer {
  padding-bottom: 34px !important;
}

.password-input .was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: right calc(0.375em + 2rem) center;
}

[dir="rtl"] {
  .rtl-flex {
    flex-direction: row-reverse !important;
  }
  .bi-search {
    left: 12px;
  }
  .password-revealer {
    right: inherit;
    left: 12px;
  }
  .password-input .was-validated .form-control:invalid,
  .form-control.is-invalid {
    background-position: left calc(0.375em + 2rem) center;
    padding-right: 16px !important;
  }

  .amount-input {
    .minus {
      border-top-right-radius: 12px !important;
      border-bottom-right-radius: 12px !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      border-left-width: 0 !important;
      border-right-width: 2px !important;
    }
    .plus {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-right-width: 0 !important;
      border-left-width: 1px !important;
    }
    .input-group-text {
      border-top-left-radius: 12px !important;
      border-bottom-left-radius: 12px !important;
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      border-bottom-width: 2px !important;
    }
  }
}

.breadcrumb {
  .breadcrumb-item {
    font-weight: $font-weight-semibold;
    color: $black;
  }
  .breadcrumb-item.active {
    color: #1570EF;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-12px);
  }
  60% {
    transform: translateY(-6px);
  }
}

.bounce {
  animation: bounce 1.2s ease-in-out;
}

