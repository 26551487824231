.react-select {
  .select__control {
    border-radius: $border-radius;
    padding: 4px 8px;
    &:hover{
        box-shadow: none;
        border-color: $light !important;
    }
  }
  // .react-select__value-container {
  //   padding: 8px;
  // }
  // .react-select__multi-value {
  //   display:none;
  //   flex-direction: row-reverse;
  //   color: $graycool-500;
  //   background-color: $white;
  //   border: 1px solid $light;
  //   border-radius: $border-radius-pill;
  //   padding: 2px 4px;
  //   .react-select__multi-value__label {
  //     padding: 3px;
  //     font-size: $body3-font-size;
  //   }
  // }
}
