@use "sass:math";

.wizard-indicator {
  height: 32px;
  width: 32px;
  background-color: $primary;
  color: $white;
  &.inactive {
    background-color: $white;
    border: 1px solid $graycool-300;
    color: $graycool-300;
  }
  &.success {
    background-color: $success;
  }
}

.forms-wizard-vertical {
  .forms-wizard {
    display: block;
    width: 100%;
    float: left;
    padding: 0 $layout-spacer-x $layout-spacer-x 0;

    li {
      text-align: left;
      display: flex;
      align-items: center;
      align-content: flex-start;
      padding: math.div($layout-spacer-x, 2);
      margin-bottom: math.div($layout-spacer-x, 3);
      @include border-radius($border-radius-lg);
      transition: all 0.2s;

      &::before,
      &::after {
        display: none;
      }

      em {
        line-height: 32px;
        margin: 0 math.div($layout-spacer-x, 2) 0 0;
      }

      &:hover {
        background: $gray-200;
      }

      &.form-wizard-step-doing {
        // background: $primary;
        color: $dark;

        em {
          background-color: $primary;
        }
      }
    }
  }

  .form-wizard-content {
    width: 70%;
    overflow: auto;
  }
}

.forms-wizard {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  display: table;
  table-layout: fixed;
  @include border-radius($border-radius);

  li {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    font-size: math.div($font-size-lg, 1.1);
    padding: math.div($layout-spacer-x, 1.5) 0;
    color: $graycool-700;
    position: relative;
    font-weight: $font-weight-bold;

    &::after,
    &::before {
      position: absolute;
      height: 1px;
      top: 50%;
      margin-top: -(math.div($layout-spacer-x, 1.5));
      width: 50%;
      content: "";
      background: $gray-300;
      z-index: 5;
      transition: all 0.2s;
    }

    &::after {
      left: 50%;
    }

    &::before {
      left: 0;
    }

    &:first-child {
      &::before {
        display: none;
        //   @include border-start-radius(20px);
      }
    }

    &:last-child {
      &::after {
        display: none;
        // @include border-end-radius(20px);
      }
    }

    em {
      font-style: normal;
      background-color: $white;
      border: 1px solid $graycool-300;
      color: $graycool-300;
      text-align: center;

      padding: 0;
      width: 32px;
      height: 32px;
      line-height: 34px;
      @include border-radius(50px);
      display: block;
      margin: 0 auto 0.4rem;
      position: relative;
      z-index: 7;
      transition: all 0.2s;
    }

    &.form-wizard-step-todo {
      span {
        font-weight: $font-weight-normal;
      }
    }

    &.form-wizard-step-doing {
      color: $primary;
      em {
        border: none;
        color: $white;
        background: $primary;
      }

      &::before {
        background: $primary;
      }
    }

    &.form-wizard-step-done {
      span {
        font-weight: $font-weight-normal;
      }
      em {
        border: none;
        color: $white;
        background: $success;
        overflow: hidden;
        &::before {
          width: 42px;
          height: 42px;
          font-size: 1.2rem;
          line-height: 40px;
          text-align: center;
          display: block;
        }
      }

      &::after,
      &::before {
        background: $success;
      }
    }

    &:hover {
      color: $gray-600;
    }
  }
}

[dir="rtl"] {
  .forms-wizard {
    li {
      &::after {
        left: 0;
        right: 50%;
      }

      &::before {
        right: 0;
      }
    }
  }
}
