.table {
  th {
    color: $graycool-600;
    font-size: 0.75rem;
  }
  td {
    color: $graycool-600;
    font-size: 0.875rem;
  }

  th,td{
    padding:0.25rem;
    @include media-breakpoint-up(lg){
      padding:1rem 1rem 1rem 1.5rem;
    }
  }
}