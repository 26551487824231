.card {
  padding: 0rem;
  @include media-breakpoint-up(lg) {
    padding: 0rem;
  }
  .card-header {
    margin:1rem;
    @include media-breakpoint-up(lg) {
      padding: 1rem 1rem 1.5rem 1rem;
    }
    padding-top: 0;
  }
  .card-body {
    padding: 1rem;
    padding-bottom: 0;
  }
  .card-footer{
    background-color: $graycool-50;
    text-align: center;
    padding:1rem;
    font-weight: $font-weight-bold;
    color: $graycool-700;
  }
}
