.nav.mobile-navbar {
  z-index: 1000 !important;
  background-color: $indigo-600;

  .nav-item {
    a {
      font-size: 12px;
      text-decoration: none;
      color: $white;

      .text {
        display: none;
      }
      &.active {
        .text {
          display: inline;
        }
        border-radius: $border-radius-sm;
        padding: 8px 12px;
        background-color: $indigo-600;
      }
    }
  }
  .active {
    background-color: $indigo-400 !important;
    color: $white !important;
  }
  .more {
    padding: 0 1rem;
    width: 100vw;
    position: absolute;
    bottom: 52px;
    background-color: $indigo-500;
    color: $white;
    a {
      background-color: $indigo-500 !important;
    }
  }
}
