.badge.bg-primary {
  background-color: $primary-50 !important;
  color: $primary;
}

.badge.bg-warning {
  background-color: $yellow-100 !important;
  color: $warning;
}

.badge.bg-gray {
  background-color: $gray-100 !important;
  color: $gray-700;
}

.badge.bg-success {
  background-color: $green-100 !important;
  color: $success;
}

.badge.bg-danger {
  background-color: $red-100 !important;
  color: $danger;
}
